@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
:root {
  font-size: 10px;
}
.light-theme {
  --bg-main: #fff;
  --bg-main2: #f7f8f9;
  --blue-light: #00baf7;
  --blue-dark: #01a5fb;
  --text-color2: #131313;
  --wb60: rgba(0, 0, 0, 0.65);
  --text-color: #000;
  --bw: #000;
  --bw25: rgba(0, 0, 0, 0.15);
}
.dark-theme {
  --bg-main: #101216;
  --bg-main2: #191c22;
  --blue-light: #00baf7;
  --blue-dark: #01a5fb;
  --text-color2: #f7f8f9;
  --wb60: rgba(255, 255, 255, 0.65);
  --text-color: #fff;
  --bw25: rgba(225, 225, 225, 0.1);
  --bw: #fff;
}
.box {
  max-width: 1500px;
  padding: 0 5rem;
  margin: 0 auto;
  width: 100%;
}
html,
body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.app {
  width: 100%;
  position: relative;
}

.section-heading-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-heading {
  margin: 0 auto 7.5rem auto;
  position: relative;
}
.section-heading div {
  width: 5rem;
  height: 1px;
  background-color: var(--text-color);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-1.5rem, 0);
}
.section-heading h3 {
  font-size: 4rem;
  color: var(--text-color);
  font-weight: 700;
  text-transform: capitalize;
}
.page-heading-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.page-heading {
  margin: 0 0 7.5rem 0;
  width: 100%;
  position: relative;
}
.page-heading div {
  width: 35%;
  height: 1px;
  background-color: var(--text-color);
  position: absolute;
  bottom: 0;
  left: 0%;
  transform: translate(0rem, 0);
}
.page-heading h2 {
  font-size: 5.5rem;
  color: var(--text-color);
  font-weight: 700;
  text-transform: capitalize;
}
.page-heading-section {
  width: 100%;
}
.text-content {
  width: 100%;
  margin: 5rem 0 0 0;
  max-width: 1250px;
}
.gi-text-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 5rem;
}
.gi-text-grid div:last-child {
  grid-column: 1 / 3;
}
.text-content-box h3 {
  font-size: 3.25rem;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.text-content-box h3 b {
  color: var(--blue-dark);
}
.text-content-box h3 span {
  width: 2.5rem;
  height: 1px;
  background-color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem 0 0;
}
.text-content-box p,
.dao-p-box p,
.text-content-box li {
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 300;
  line-height: 3rem;
}
.text-content-box {
  width: 100%;
  padding: 0 0 7.5rem 0;
}
.text-content-box ul {
  width: 100%;
  padding: 0 0 0 5rem;
}
.text-content-box li:not(:last-child) {
  margin: 0 0 1rem 0;
}
.text-content-box.tcb-2 {
  padding: 0;
}
.divider {
  width: 100%;
  height: 1px;
  margin: 10rem 0;
  background-color: var(--text-color);
}
.dao-p-box {
  width: 100%;
  margin: 0 auto;
}
.dao-meaning-box {
  width: 100%;
  padding: 10rem 0;
}
.dao-meaning-box h5 {
  font-size: 2rem;
  font-weight: 300;
  color: var(--text-color);
}
.dao-meaning-box h5 span {
  font-size: 5rem;
  font-weight: 700;
  color: var(--blue-dark);
}
.dao-meaning-box h5:not(:last-child) {
  margin: 0 0 1.5rem 0;
}
.bronze {
  color: #c77b30 !important;
}
.silver {
  color: #bababa !important;
}
.gold {
  color: #f7d000 !important;
}
p {
  font-weight: 300 !important;
}
.light-theme .dark-el {
  display: none;
}
.light-theme .light-el {
  display: block;
}
.dark-theme .dark-el {
  display: block;
}
.dark-theme .light-el {
  display: none;
}
.form-container {
  display: block;
}
.top-newsletter {
  padding-top: 18px; 
}
@media screen and (max-width: 750px) {
  .box {
    padding: 0 2.5rem;
  }
  .section-heading {
    margin: 0 auto 5rem auto;
  }
  .section-heading h3 {
    font-size: 2.75rem;
  }
  .section-heading div {
    width: 3.5rem;
    transform: translate(-1rem, 0);
  }
  .section-heading-box {
    justify-content: flex-start;
  }
  .page-heading h2 {
    font-size: 3.5rem;
  }
  .divider {
    margin: 5rem 0;
  }
  .text-content-box h3 {
    font-size: 2.75rem;
  }
  .mobile-hidden {
    display: none;
  }
}
