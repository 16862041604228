footer {
  width: 100%;
  padding: 7.5rem 0;
  background-color: var(--bg-main2);
}
.footer-grid {
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1.5rem;
}
.footer-grid-card-1,
.footer-grid-card-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.footer-subscribe-box {
  width: 100%;
  margin: 0 0 2.5rem 0;
  background-color: var(--bg-main);
  border-radius: 0.75rem;
  display: flex;
  transition: 0.5s;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.footer-subscribe-box input {
  width: 100%;
  background-color: unset;
  border: 0;
  outline: 0;
  padding: 1rem 2.5rem 1rem 0;
  font-size: 1.65rem;
  color: var(--text-color);
  padding: 0 0 0 1.5rem;
}
.footer-subscribe-box input::placeholder {
  color: var(--text-color);
  opacity: 0.75;
}
.footer-con {
  width: 100%;
  display: flex;
  max-width: 1250px;
  margin: 7.5rem auto 0 auto;
  align-items: flex-start;
  justify-content: space-between;
}
.footer-grid-card-1 {
  width: max-content;
}
.footer-grid-card-1 img {
  height: 6.5rem;
}
.footer-links-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-links-box a {
  font-size: 1.5rem;
  color: var(--text-color);
  margin: 0 0 0 2.5rem;
  text-decoration: underline;
}
.footer-p {
  font-size: 1.5rem;
  color: var(--text-color);
}
.footer-subscribe-box button {
  background-color: unset;
  border: 0;
  outline: 0;
  cursor: pointer;
  font-size: 1.65rem;
  color: #fff;
  background-color: var(--blue-dark);
  font-weight: 600;
  text-transform: uppercase;
  width: 5rem;
  height: 5rem;
}
.footer-icons-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  margin-left: 120px;
}
.footer-icons-box a {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  background-color: var(--bg-main);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2.5rem 0 0;
  font-size: 2.15rem;
  color: var(--blue-dark);
  transition: 1s;
}
.footer-icons-box a svg {
  color: var(--text-color);
  width: 1.85rem;
}
.footer-icons-box a:hover {
  transform: translate(0, -0.5rem);
}
@media screen and (max-width: 1250px) {
  .footer-grid {
    grid-template-columns: 1fr 1fr;
  }
  .footer-grid-card-2 {
    order: 3;
  }
  .footer-grid-card-3 {
    order: 4;
  }
  .footer-grid-card-4 {
    order: 2;
  }
}
@media screen and (max-width: 750px) {
  .footer-con {
    margin: 5rem 0 0 0;
  }
  footer {
    padding: 5rem 0;
  }
  .footer-grid {
    grid-template-columns: 1fr;
  }
  .footer-grid-card-2 {
    order: 2;
  }
  .footer-grid-card-3 {
    order: 3;
  }
  .footer-grid-card-4 {
    order: 4;
  }
  .footer-con {
    flex-direction: column-reverse;
  }
  .footer-p {
    width: 100%;
    text-align: center;
    margin: 3.5rem 0 0 0;
  }
  .footer-links-box {
    width: 100%;
    justify-content: flex-start;
  }
  .footer-links-box a {
    margin: 0 2.5rem 0 0;
  }
}
.back-to-top {
  width: 5rem;
  height: 5rem;
  background-color: var(--blue-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 3.5rem;
  right: 3.5rem;
}
