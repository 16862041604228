.home-page {
  width: 100%;
  background-color: var(--bg-main);
  position: relative;
}
.hero-section {
  width: 100%;
  /* background-color: var(--text-color2); */
}
.hero-section-content {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 5rem;
}
.hero-left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.hero-right {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.hero-right svg {
  width: 100%;
  height: 72.5%;
  object-fit: cover;
}
.hero-left h1 {
  font-size: 5rem;
  color: var(--text-color);
  text-transform: uppercase;
}
.hero-left h1 span {
  background: linear-gradient(to right, var(--blue-light), var(--blue-dark));

  color: var(--text-color);
  padding: 0 2rem;
}
.hero-left h2 {
  font-size: 3rem;
  color: var(--text-color);
  font-weight: 300;
  margin: 5rem 0 0 0;
}
.wwd-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
}
.what-we-do {
  width: 100%;
  padding: 7.5rem 0;
}
.wwd-grid {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 0 0 1.5rem 0;
}
.wwd-grid-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.wwd-grid-card img {
  width: 20rem;
  object-fit: cover;
  margin: 2.5rem 0;
  height: 20rem;
}
.wwd-grid-card h4 {
  font-size: 2.25rem;
  color: var(--text-color2);
  font-weight: 600;
  color: var(--blue-dark);
  text-transform: capitalize;
}
.wwd-grid-card p {
  font-size: 1.6rem;
  color: var(--text-color2);
  font-weight: 400;
  width: 60%;
}
.wwd-line {
  height: 15rem;
  background-color: var(--text-color2);
  margin: 1.5rem 0;
  width: 1px;
}
.df {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blue-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 3rem;
  border-radius: 0.75rem;
  color: #fff;
  font-size: 1.85rem;
  font-weight: 500;
  cursor: pointer;
  border: 0;
  outline: 0;
  background: var(--blue-dark);
  width: max-content;
  text-decoration: none;
}
.founding-members {
  width: 100%;
  background-color: var(--bg-main2);
  padding: 7.5rem 0;
}
.fm-grid-box-con {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fm-grid-swiper {
  width: 100%;
  padding: 0 2.5rem;
}
.fm-grid-swiper .swiper-slide {
  height: unset;
}
.fm-img-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.fm-img-box img {
  object-fit: contain;
}
.fm-grid-box {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fm-grid-swiper .swiper-pagination {
  position: relative;
  bottom: unset;
  left: unset;
  margin: 5rem 0 0 0;
  /* transform: translate(0, 2.5rem); */
}
.fm-grid-swiper .swiper-pagination .swiper-pagination-bullet {
  background-color: var(--bw25);
  opacity: 1;
  width: 8px;
  height: 8px;
  border-radius: 1000px;
  transition: 0.35s;
}
.fm-grid-swiper
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--wb60);
  width: 25px;
  height: 8px;
  border-radius: 1000px;
}
.fm-arrow-box {
  min-width: 5rem;
  max-width: 5rem;
  max-height: 5rem;
  min-height: 5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  cursor: pointer;
  transition: 0.35s;
  color: var(--wb60);
}
.fm-arrow-box:hover {
  color: var(--bw);
}
.fm-grid {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 5rem;
}
.fm-grid img {
  max-height: 6rem;
  min-width: 10rem;
  width: max-content;
  object-fit: contain;
}
.join-real-world {
  width: 100%;
  padding: 7.5rem 0;
}
.jrw-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.jrw-section p {
  font-size: 2rem;
  color: var(--text-color);
  font-weight: 300;
  text-align: center;
  max-width: 750px;
  width: 100%;
}
.icons-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem 0 0 0;
}
.icons-box a {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  background: var(--bg-main2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2.5rem;
  font-size: 2.15rem;
  color: var(--text-color);
  transition: 1s;
}
.icons-box a:hover {
  transform: translate(0, -0.5rem);
}
.wwd-box {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 15rem;
  margin: 0 0 7.5rem 0;
}
.wwd-box-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.wwd-box-card svg {
  width: 100%;
  object-fit: cover;
}
.wwd-box-card h4 {
  font-size: 2.25rem;
  font-weight: 600;
  text-align: center;
  color: var(--blue-dark);
  text-transform: capitalize;
  margin: 5rem 0 1.5rem 0;
}
.wwd-box-card p {
  font-size: 1.6rem;
  color: var(--text-color2);
  font-weight: 400;
  text-align: center;
}
.home-text-con {
  width: 100%;
  padding: 0 0 7.5rem 0;
}
.contact-btn-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem 0 5rem 0;
}
.no-padding {
  padding: 0 !important;
}
.home-chart-box {
  width: 100%;
  margin: 7.5rem 0 0 0;
}
.home-chart-box h4 {
  color: var(--blue-dark);
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0 0 5rem 0;
  text-align: center;
  width: 100%;
}
.chart-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chart-box canvas {
  object-fit: contain;
  width: 100%;
  max-height: 40rem;
  max-width: 1000px;
}
/* RESPONSIVENESS  */
@media screen and (max-width: 1140px) {
  .hero-left {
    padding: 15rem 0 0 0;
  }
  .hero-left h1 {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 1000px) {
  .hero-section-content {
    grid-template-columns: 1fr;
  }
  .wwd-box {
    grid-template-columns: 1fr 1fr;
    grid-gap: 5rem;
  }
}

@media screen and (max-width: 750px) {
  .wwd-line {
    height: 10rem;
  }
  .wwd-box {
    grid-template-columns: 1fr;
    grid-gap: 5rem;
  }
  .hero-left h2 {
    font-size: 2rem;
  }
  .hero-left h1 {
    font-size: 3rem;
  }
  .hero-left {
    padding: 10rem 0 0 0;
  }
  .wwd-grid-card h4 {
    width: 100%;
  }
  .fm-arrow-box {
    display: none;
  }
  .fm-grid-swiper {
    padding: 0;
  }
  .fm-grid-swiper .swiper-slide {
    width: max-content;
  }
  .hero-left h1 span {
    padding: 0 1rem;
  }
  .hero-right svg {
    height: 100%;
  }
  .wwd-grid-card p {
    width: 80%;
  }
  .wwd-box-card svg {
    width: 75%;
  }
  .hero-left h1 {
  }
  .hero-left h1 span {
    color: var(--blue-dark);
    background: unset;
    padding: 0;
  }
}
