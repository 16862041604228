header {
  width: 100%;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
}
.header-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5rem 0;
  height: 15rem;
}
.header-left {
  width: max-content;
}
.header-left img {
  height: 7.5rem;
}
.header-right {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header-right > * {
  margin: 0 0 0 3.5rem;
}
.header-right a {
  font-size: 1.75rem;
  font-weight: 300;
  color: var(--text-color);
  text-decoration: none;
}
.header-right a:hover {
  color: var(--blue-dark);
}
.light-dark-switch-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--text-color);
  position: relative;
  overflow: hidden;
  border-radius: 1000px;
  cursor: pointer;
}
.light-dark-switch-box span {
  width: 5rem;
  height: 3.5rem;
  display: flex;
  z-index: 15;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  font-size: 2rem;
}
.light-dark-switch-box span.active-theme {
  background-color: var(--text-color);
  color: var(--bg-main);
}
.light-dark-switch-box span.inactive-theme {
  color: var(--text-color);
  background-color: var(--bg-main);
}
.header-icon-btn {
  font-size: 2.75rem;
  color: var(--text-color);
}
.header-mob {
  width: 100%;
  height: 100vh;
  background-color: var(--bg-main);
  position: fixed;
  top: 0;
  left: 0;
}
.header-mob-top {
  width: 100%;
  display: flex;
  align-items: center;
  height: 10rem;
  justify-content: space-between;
}
.header-mob-top img {
  height: 5rem;
}
.header-icon-btn {
  display: none;
}
.header-mob-center > span {
  display: block;
  margin: 0 0 3.5rem 0;
}
.newsletter-top {
  position: absolute;
  top: 10px;
  right: 10px;
}
@media screen and (max-width: 750px) {
  .header-left img {
    height: 5rem;
  }
  .text-content-box h3 span {
    width: 1.5rem;
  }
  .text-content-box h3 span {
    display: none;
  }
  .text-content-box h3 {
    display: block;
  }
  .text-content-box ul {
    padding: 0 0 0 2rem;
  }
  .text-content-box {
    padding: 0 0 5rem 0;
  }
  .text-content-box p,
  .dao-p-box p,
  .text-content-box li {
    font-size: 1.75rem;
  }
  .header-icon-btn {
    display: block;
  }
  .header-right > a,
  .header-right > .light-dark-switch-box {
    display: none;
  }
  .header-content {
    height: 10rem;
  }
  .header-mob-center {
    margin: 5rem 0 0 0;
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .header-mob-center a {
    font-size: 2rem;
    color: var(--text-color);
    text-decoration: none;
  }
  .header-mob-center a:hover {
    color: var(--blue-dark);
  }
}
