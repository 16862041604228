.about-page {
  width: 100%;
  background-color: var(--bg-main);
  position: relative;
  min-height: 100vh;
}
.page-heading-section {
  width: 100%;
  padding: 22.5rem 0 0 0;
}
.tcb-grid {
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: auto;
  grid-gap: 2.5rem;
}
.tcb-img {
  width: 100%;
}
.tcb-img img {
  width: 100%;
  max-height: 6.5rem;
  object-fit: contain;
}
.tcb-grid-box {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 15rem;
  padding: 0 0 5rem 0;
}
/* ROADMAP SECTION  */
.roadmap-content {
  width: 90%;
  position: relative;
  margin: 0 auto;
  padding: 4rem 0;
}
.roadmap {
  width: 100%;
  padding: 0 0 7.5rem 0;
}
.road-map-line {
  width: 0.25rem;
  height: 100%;
  background: var(--text-color2);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.roadmap-box-left {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.roadmap-box-right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5rem 0;
}
.roadmap-box-content {
  width: 50%;
  display: flex;
  align-items: center;
}
.roadmap-text-box {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid var(--text-color2);
  padding: 2.5rem;
  border-radius: 1rem;
  color: var(--text-color2);
  font-size: 1.75rem;
}
.roadmap-text-box p {
  margin: 0.65rem 0;
}
.roadmap-text-box h6 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0 0 1.5rem 0;
}
.roadmap-circle {
  min-width: 6.5rem;
  min-height: 6.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--text-color2);
  background: var(--text-color2);
  font-size: 2.5rem;
  color: var(--bg-main);
  font-weight: 600;
  position: relative;
  z-index: 100;
}
.roadmap-box-left .roadmap-circle {
  transform: translate(3.25rem, 0);
}
.roadmap-box-right .roadmap-circle {
  transform: translate(-3.25rem, 0);
}

/* GET INVOLVED  */
.become-member-content {
  padding: 0 0 10rem 0;
}
.p-p {
  color: var(--text-color);
  font-size: 2rem;
  text-align: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
.become-member-content .section-heading {
  margin: 0 auto 3rem auto;
}
.membership-packages {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5rem;
  width: 90%;
  margin: 4rem auto;
  padding: 4rem 0;
}
.packages-content {
  border: 1px solid var(--text-color2);
  border-radius: 1rem;
  padding: 3.5rem;
}
.packages-content:nth-child(1) {
  border: 1px solid #c77b30;
}
.packages-content:nth-child(2) {
  border: 1px solid #bababa;
}
.packages-content:nth-child(3) {
  border: 1px solid #f7d000;
}
.packages-content h5 {
  font-size: 2.5rem;
  color: var(--text-color2);
  font-weight: 700;
  margin: 1.5rem 0 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.packages-content h5 img {
  width: 3.5rem;
  margin: 0 1rem 0 0;
}
.packages-content p {
  font-size: 2rem;
  color: var(--text-color2);
  font-weight: 400;
  margin: 2.5rem 0 0 0;
}
.packages-content p svg {
  color: #0e9956;
  margin: 0 1rem 0 0;
}
.blue-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 3rem;
  border-radius: 0.75rem;
  color: var(--text-color2);
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
  border: 0;
  outline: 0;
  background: var(--blue-dark);
}
.become-member-content .df {
  margin: 6rem auto 0 auto;
  width: max-content;
}

/* CONTACT US  */
.form-grid {
  width: 100%;
  margin: 0 auto;
  max-width: 750px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 5rem 4rem;
}
.form-grid textarea {
  grid-column: 1/-1;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 10rem;
  resize: vertical;
}
.form-grid input,
.form-grid textarea {
  border: 1px solid var(--text-color2);
  border-radius: 0.5rem;
  font-size: 1.5rem;
  color: var(--text-color2);
  font-weight: 400;
  padding: 1.5rem;
  width: 100%;
  background-color: transparent;
}
.form-grid textarea {
}
.form-grid input:focus,
.form-grid textarea:focus {
  font-size: 1.75rem;
  outline: none;
}
.form-grid input::placeholder,
.form-grid textarea::placeholder {
  font-size: 1.5rem;
  color: var(--text-color2);
  font-weight: 500;
}
.contact-section .p-p {
  max-width: 750px;
  width: 100%;
  margin: 0 auto 4rem auto;
}
.social-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 2rem;
  max-width: 550px;
  width: 100%;
  margin: 3rem auto;
  padding: 0 0 3rem 0;
}
.social-grid span {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--text-color2);
  border-radius: 50%;
  width: 4.5rem;
  height: 4.5rem;
  max-height: 4.5rem;
  max-width: 4.5rem;
  cursor: pointer;
  transition: 1s;
}
.social-grid span svg {
  color: var(--bg-main);
  font-size: 2.5rem;
}
.social-grid span {
  transform: translate(0, -0.5rem);
}
.text-content-box > img {
  width: max-content;
  height: 16rem;
  margin: 3.5rem 0 0 0;
  object-fit: contain;
}
.mb-heading {
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: flex-start;
  font-size: 2.25rem;
  color: var(--text-color);
}
.mb-heading img {
  width: 5rem;
  object-fit: contain;
  margin: 0 1.5rem 0 0;
}
.become-member-content .p-p {
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  line-height: 3rem;
}

/* RESPONSIVENESS  */
@media screen and (max-width: 1140px) {
  .membership-packages {
    width: 100%;
  }
  .packages-content {
    padding: 2rem;
  }
}
@media screen and (max-width: 1000px) {
  .road-map-line {
    left: 0;
    transform: translate(0, 0);
  }
  .roadmap-box-content {
    width: 100%;
  }
  .roadmap-box-left .roadmap-box-content {
    flex-direction: row-reverse;
  }
  .roadmap-box-left .roadmap-circle {
    transform: translate(-3.25rem, 0);
  }
  .tcb-grid-box {
    grid-template-columns: 1fr;
    grid-gap: 7rem;
  }
  .gi-text-grid {
    grid-template-columns: 1fr;
  }
  .gi-text-grid div:last-child {
    grid-column: unset;
  }
  .membership-packages {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 750px) {
  .tcb-img img {
    width: max-content;
  }
  .page-heading-section {
    padding: 12.5rem 0 0 0;
  }
  .page-heading-section .roadmap-content {
    width: 100%;
  }
  .roadmap-circle {
    min-width: 5rem;
    min-height: 5rem;
    font-size: 2rem;
  }
  .roadmap-box-left .roadmap-circle,
  .roadmap-box-right .roadmap-circle {
    transform: translate(-1rem, 0);
  }
  .roadmap-text-box {
    font-size: 1.5rem;
    padding: 1.5rem;
  }
  .roadmap-text-box h6 {
    font-size: 2rem;
  }
  .road-map-line {
    transform: translate(1.25rem, 0);
  }
  .roadmap-box-right {
    margin: 2.5rem 0;
  }
  .tcb-grid {
    grid-template-columns: 1fr;
  }
  .form-grid {
    grid-template-columns: 1fr;
    grid-gap: 3.5rem;
  }
}
